// src/contexts/pricebook-context.tsx
import * as React from 'react';

import type { Pricebook } from '@/types/pricebook';
import { usePricebooks } from '@/hooks/use-pricebooks';

interface PricebookContextValue {
  pricebooks: Map<number, Pricebook>;
  loading: boolean;
  error: Error | null;
  addPricebook: (pricebook: Pricebook) => void;
  updatePricebook: (id: number, updates: Partial<Pricebook>) => void;
  archivePricebook: (id: number, targetPricebookId?: number) => void;
  restorePricebook: (id: number) => void;
  getPricebook: (id: number) => Pricebook | undefined;
  refreshPricebooks: () => Promise<void>;
}

interface ProviderProps {
  children: React.ReactNode;
}

export const PricebookContext = React.createContext<PricebookContextValue>({
  pricebooks: new Map(),
  loading: false,
  error: null,
  addPricebook: () => undefined,
  updatePricebook: () => undefined,
  archivePricebook: () => undefined,
  restorePricebook: () => undefined,
  getPricebook: () => undefined,
  refreshPricebooks: async () => undefined,
});

export function PricebookProvider({ children }: ProviderProps): React.JSX.Element {
  const { pricebooks: fetchedPricebooks, loading, error, refreshPricebooks } = usePricebooks();
  const [pricebooks, setPricebooks] = React.useState<Map<number, Pricebook>>(new Map());

  React.useEffect(() => {
    setPricebooks((prev) => {
      const updated = new Map(prev);
      fetchedPricebooks.forEach((item) => {
        if (item.pricebookID) {
          updated.set(item.pricebookID, item);
        }
      });
      return updated;
    });
  }, [fetchedPricebooks]);

  const handleAdd = React.useCallback(
    async (pricebook: Pricebook) => {
      setPricebooks((prev) => {
        const updated = new Map(prev);
        if (pricebook.pricebookID) {
          updated.set(pricebook.pricebookID, {
            ...pricebook,
            itemsCount: pricebook.itemsCount ?? 0,
          });
        }
        return updated;
      });
      await refreshPricebooks();
    },
    [refreshPricebooks]
  );

  const handleUpdate = React.useCallback(
    async (id: number, updates: Partial<Pricebook>) => {
      setPricebooks((prev) => {
        const existing = prev.get(id);
        if (!existing) return prev;

        const updated = new Map(prev);
        updated.set(id, { ...existing, ...updates });
        return updated;
      });
      await refreshPricebooks();
    },
    [refreshPricebooks]
  );

  const handleArchive = React.useCallback(
    async (id: number, targetPricebookId?: number) => {
      setPricebooks((prev) => {
        const existing = prev.get(id);
        if (!existing) return prev;

        const updated = new Map(prev);
        updated.set(id, { ...existing, status: 'archived' });

        // If transferring items, update item counts
        if (targetPricebookId) {
          const targetPricebook = updated.get(targetPricebookId);
          if (targetPricebook) {
            updated.set(targetPricebookId, {
              ...targetPricebook,
              itemsCount: (targetPricebook.itemsCount || 0) + (existing.itemsCount || 0),
            });
          }
        }

        return updated;
      });
      await refreshPricebooks();
    },
    [refreshPricebooks]
  );

  const handleRestore = React.useCallback(
    async (id: number) => {
      setPricebooks((prev) => {
        const existing = prev.get(id);
        if (!existing) return prev;

        const updated = new Map(prev);
        updated.set(id, { ...existing, status: 'active' });
        return updated;
      });
      await refreshPricebooks();
    },
    [refreshPricebooks]
  );

  const getPricebook = React.useCallback(
    (id: number): Pricebook | undefined => {
      return pricebooks.get(id);
    },
    [pricebooks]
  );

  const value = React.useMemo(
    () => ({
      pricebooks,
      loading,
      error,
      addPricebook: handleAdd,
      updatePricebook: handleUpdate,
      archivePricebook: handleArchive,
      restorePricebook: handleRestore,
      getPricebook,
      refreshPricebooks,
    }),
    [pricebooks, loading, error, handleAdd, handleUpdate, handleArchive, handleRestore, getPricebook, refreshPricebooks]
  );

  return <PricebookContext.Provider value={value}>{children}</PricebookContext.Provider>;
}

export function usePricebookContext(): PricebookContextValue {
  return React.useContext(PricebookContext);
}
