// routes/quick-start-guide.tsx
import * as React from 'react';
import { logError } from '@/utils/log-error';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';
import { ClientsProvider } from '@/contexts/clients-context';
import { PricebookItemsProvider } from '@/contexts/pricebook-items-context';
import { ServiceRecordsProvider } from '@/contexts/service-records-context';
import { TaxRatesProvider } from '@/contexts/tax-rates-context';
import { ErrorFallback } from '@/components/core/error-fallback';
import { Layout } from '@/components/hub/layout/layout';
import { ProtectedPage } from '@/components/hub/layout/protected-page';

function QuickstartProviders({ children }: { children: React.ReactNode }): React.JSX.Element {
  return (
    <ClientsProvider>
      <PricebookItemsProvider>
        <ServiceRecordsProvider>
          <TaxRatesProvider>{children}</TaxRatesProvider>
        </ServiceRecordsProvider>
      </PricebookItemsProvider>
    </ClientsProvider>
  );
}

export const route: RouteObject = {
  element: (
    <Layout>
      <Outlet />
    </Layout>
  ),
  children: [
    {
      path: 'quickstart',
      index: true,
      lazy: async () => {
        const { Page } = await import('@/pages/quickstart/index');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0]}>
                  <QuickstartProviders>
                    <Page />
                  </QuickstartProviders>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
  ],
};
