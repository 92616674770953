// src/hooks/use-pricebook-items.ts
import * as React from 'react';
import getPricebookItems from '@/reqs/get-pricebook-items';

import type { PricebookItem } from '@/types/pricebook';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface PricebookItemsResponse {
  pricebookItems: PricebookItem[];
  loading: boolean;
  error: Error | null;
  refreshPricebookItems: () => Promise<void>;
  initialLoad: boolean;
}

export function usePricebookItems(pricebookId?: number): PricebookItemsResponse {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';
  const now = new Date().getTime();

  const [pricebookItems, setPricebookItems] = React.useState<PricebookItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error | null>(null);
  const [lastLoadedAt, setLastLoadedAt] = React.useState<number>(now);
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!companyID) {
      setLoading(false);
      setError(new Error('No company ID available'));
      return;
    }

    const abortController = new AbortController();

    const fetchPricebookItems = async (): Promise<void> => {
      try {
        setLoading(true);
        setError(null);

        const response = await getPricebookItems(
          { companyID, ...(pricebookId ? { pricebookId } : {}) },
          abortController,
          token
        );
        setPricebookItems(response);
        setInitialLoad(false);
      } catch (e) {
        const exception = e as Error;
        if (exception.name !== 'AbortError') {
          setPricebookItems([]);
          setError(exception);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPricebookItems();

    return () => {
      abortController.abort();
    };
  }, [companyID, pricebookId, lastLoadedAt, token]);

  const refreshPricebookItems = React.useCallback(async (): Promise<void> => {
    setLastLoadedAt(new Date().getTime());
  }, []);

  return {
    pricebookItems,
    loading,
    error,
    refreshPricebookItems,
    initialLoad,
  };
}
