export const MODULE_COLORS = {
  clients: {
    main: '#116DFF',
    light: '#438BFF',
    dark: '#0054DB',
    darkest: '#001A46',
    lightest: 'rgba(17, 109, 255, 0.1)',
  },
  jobs: {
    main: '#FF8A00',
    light: '#FFA533',
    dark: '#CC6E00',
    darkest: '#413300',
    lightest: 'rgba(255, 138, 0, 0.1)',
  },
  invoices: {
    main: '#00C853',
    light: '#33D375',
    dark: '#00A042',
    darkest: '#082B00',
    lightest: 'rgba(0, 200, 83, 0.1)',
  },
  pricebook: {
    main: '#00968D',
    light: '#33ABA4',
    dark: '#007871',
    darkest: '#002422',
    lightest: 'rgba(0, 150, 141, 0.1)',
  },
  general: {
    main: '#e0e0e0',
    light: '#a8a8a8',
    dark: '#2e2e2e',
    darkest: '#1c1c1c',
    lightest: 'rgba(224, 224, 224, 0.1)',
  },
} as const;

export type ModuleType = keyof typeof MODULE_COLORS;

export function getModuleColor(
  module: ModuleType,
  variant: 'main' | 'light' | 'dark' | 'darkest' | 'lightest' = 'main'
): string {
  return MODULE_COLORS[module][variant];
}
