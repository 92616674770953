// src/hooks/use-pricebooks.ts
import * as React from 'react';
import getPricebooks from '@/reqs/get-pricebooks';

import type { Pricebook } from '@/types/pricebook';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface PricebooksResponse {
  pricebooks: Pricebook[];
  loading: boolean;
  error: Error | null;
  refreshPricebooks: () => Promise<void>;
}

export function usePricebooks(): PricebooksResponse {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  const [pricebooks, setPricebooks] = React.useState<Pricebook[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);

  const fetchPricebooks = React.useCallback(async () => {
    if (!companyID) return;

    setLoading(true);
    setError(null);

    try {
      const abortController = new AbortController();
      const response = await getPricebooks({ companyID }, abortController, token);
      setPricebooks(response);
    } catch (e) {
      const exception = e as Error;
      if (exception.name !== 'AbortError') {
        setPricebooks([]);
        setError(exception);
      }
    } finally {
      setLoading(false);
    }
  }, [companyID, token]);

  // Initial load
  React.useEffect(() => {
    fetchPricebooks();
  }, [fetchPricebooks]);

  return {
    pricebooks,
    loading,
    error,
    refreshPricebooks: fetchPricebooks,
  };
}
