import type { PricebookItem } from '@/types/pricebook';
import { logger } from '@/lib/default-logger';

import getDefaultHeaders from './default-headers';

interface CopyPricebookItemParams {
  itemId: number;
  companyID: string;
  userId: number;
}

function isPricebookItem(obj: unknown): obj is PricebookItem {
  if (typeof obj !== 'object' || obj === null) return false;
  const item = obj as PricebookItem;
  return typeof item.pricebookItemID === 'number' && typeof item.itemName === 'string';
}

export async function copyPricebookItem(params: CopyPricebookItemParams, token: string): Promise<PricebookItem> {
  const headers = getDefaultHeaders(token);
  logger.debug('Copying item with params:', params);

  try {
    const response = await fetch(`/api/PricebookItem`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        action: 'copy',
        itemId: params.itemId,
        companyID: params.companyID,
        userId: params.userId,
      }),
    });

    const rawData: unknown = await response.json();

    if (!response.ok) {
      const msg =
        typeof rawData === 'object' && rawData !== null ? (rawData as { message?: string }).message : undefined;
      throw new Error(msg || 'Failed to copy item');
    }

    if (!isPricebookItem(rawData)) {
      throw new Error('Invalid response format from server');
    }

    const data: PricebookItem = rawData;

    return data;
  } catch (error) {
    logger.error('Copy request failed:', error);
    throw error;
  }
}
