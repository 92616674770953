'use client';

import * as React from 'react';
import type { StripeCustomerSubscription } from '@/reqs/stripe/types';
import { isSubscriptionCanceled } from '@/utils/stripe-subscription/cancelation';
import { Navigate, useLocation } from 'react-router-dom';

import type { Company } from '@/types/company';
import { paths } from '@/paths';
import { useCompany } from '@/hooks/use-companies';
import useIntercom from '@/hooks/use-intercom';
import { useStripeSubscription } from '@/hooks/use-stripe-subscription';
import { Loading } from '@/components/core/loading';
import { toast } from '@/components/core/toaster';
import Paywall, { Reason } from '@/components/paywall/index';

import { useUserContext } from './auth/auth0/user-context';

export interface CompanyContextValue {
  company: Company | null;
  subscription: StripeCustomerSubscription | null;
  updateCompanyTimezone?: (timezone: string) => Promise<void>;
  companyTimezone: string;
}

export const CompanyContext = React.createContext<CompanyContextValue>({
  company: null,
  subscription: null,
  companyTimezone: 'UTC',
});

export interface CompanyProviderProps {
  children: React.ReactNode;
}

export function useCompanyContext(): CompanyContextValue {
  return React.useContext(CompanyContext);
}

export function CompanyProvider({ children }: CompanyProviderProps): React.JSX.Element {
  const location = useLocation();
  const { user, permissions } = useUserContext();
  const { company, loading: companyLoading, refresh, update: updateCompanyDetails } = useCompany();
  const { subscription: stripeData, loading: subscriptionLoading, loaded } = useStripeSubscription(company);
  const { loading: intercomLoading } = useIntercom(company);

  const loading = companyLoading || subscriptionLoading || intercomLoading;
  const subscriptionStatus = isSubscriptionCanceled(stripeData);

  const companyTimezone = React.useMemo(() => {
    return company?.timeZone || 'UTC';
  }, [company?.timeZone]);

  const updateCompanyTimezone = React.useCallback(
    async (timezone: string) => {
      if (!company || !permissions.SET_COMPANY_METADATA || !updateCompanyDetails) {
        return;
      }
      try {
        await updateCompanyDetails({ ...company, timeZone: timezone });
        refresh();
        toast.success('Company timezone updated successfully');
      } catch (error) {
        toast.error('Failed to update company timezone');
        throw error;
      }
    },
    [company, permissions.SET_COMPANY_METADATA, updateCompanyDetails, refresh]
  );

  const contextValue = React.useMemo(
    () => ({
      company,
      subscription: stripeData,
      updateCompanyTimezone: permissions.SET_COMPANY_METADATA ? updateCompanyTimezone : undefined,
      companyTimezone,
    }),
    [company, stripeData, permissions.SET_COMPANY_METADATA, updateCompanyTimezone, companyTimezone]
  );

  const companyIsOnboarded = (company?.onboarded ?? true) as boolean;
  const isOnboardingRoute = location.pathname === paths.quickstart || location.pathname === paths.adminOnboarding;
  const userIsOnboarded = [user?.firstName, user?.lastName, user?.contactNumber, user?.jobTitle].every((value) =>
    Boolean(value)
  );
  const isUserOnboardingRoute = location.pathname === paths.quickstart || location.pathname === paths.userOnboarding;

  return (
    <CompanyContext.Provider value={contextValue}>
      {loaded && subscriptionStatus.canceled ? (
        <Paywall onClose={refresh} open reason={subscriptionStatus.reason ?? Reason.Denied} subscription={stripeData} />
      ) : !companyIsOnboarded && !isOnboardingRoute ? (
        <Navigate to={paths.adminOnboarding} />
      ) : companyIsOnboarded && user !== null && !userIsOnboarded && !isUserOnboardingRoute ? (
        <Navigate to={paths.userOnboarding} />
      ) : loading ? (
        <Loading />
      ) : (
        children
      )}
    </CompanyContext.Provider>
  );
}

export const CompanyConsumer = CompanyContext.Consumer;
