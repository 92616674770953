// src/reqs/get-pricebooks.ts
import type { Pricebook } from '../types/pricebook';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface PricebookSearchParams {
  companyID: string;
}

async function getPricebooks(
  params: PricebookSearchParams,
  abortController: AbortController,
  token: string
): Promise<Pricebook[]> {
  const searchParams = new URLSearchParams({
    companyID: params.companyID,
  }).toString();

  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/pricebook?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<Pricebook[]>;
}

export default getPricebooks;
