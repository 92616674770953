// src/reqs/update-pricebook-items-status.ts
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface UpdateItemsStatusParams {
  pricebookID: string;
  companyID: string;
  isActive: boolean;
}

async function updatePricebookItemsStatus(params: UpdateItemsStatusParams, token: string): Promise<void> {
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/pricebook/items/status`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify(params),
  });

  if (!response.ok) handleAsyncError(response);
}

export default updatePricebookItemsStatus;
