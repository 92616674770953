import type { ServiceRecord } from '@/types/service-record';

import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface ServiceRecordSearchParams {
  companyID: string;
}

/**
 * Get service records by company
 */
async function getServiceRecords(
  params: ServiceRecordSearchParams,
  abortController: AbortController,
  token: string
): Promise<ServiceRecord[]> {
  const searchParams: string = new URLSearchParams({ ...params, action: 'fetchAndCompileJobData' }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/jobs?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<ServiceRecord[]>;
}

export default getServiceRecords;
