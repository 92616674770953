import * as React from 'react';
import { copyPricebookItem } from '@/reqs/copy-pricebook-item';
import { movePricebookItems } from '@/reqs/move-pricebook-items';
import updatePricebookItemsStatus from '@/reqs/update-pricebook-item-status';

import type { PricebookItem } from '@/types/pricebook';
import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { usePricebookItems } from '@/hooks/use-pricebook-items';
import { Loading } from '@/components/core/loading';
import { toast } from '@/components/core/toaster';

interface PricebookItemContextValue {
  copyItem: (itemId: number) => Promise<PricebookItem>;
  error: Error | null;
  loading: boolean;
  moveItems: (selectedItemIds: number[], targetPricebookId: number) => Promise<void>;
  pricebookItems: PricebookItem[];
  refreshPricebookItems: () => Promise<void>;
  updateItemsStatus: (pricebookId: number, active: boolean) => Promise<void>;
}

interface PricebookItemsProviderProps {
  children: React.ReactNode;
  pricebookId?: number;
}

const PricebookItemsContext = React.createContext<PricebookItemContextValue>({
  copyItem: async () => {
    throw new Error('Not implemented');
  },
  error: null,
  loading: false,
  moveItems: async () => undefined,
  pricebookItems: [],
  refreshPricebookItems: async () => undefined,
  updateItemsStatus: async () => undefined,
});

export function PricebookItemsProvider({
  children,
  pricebookId: initialPricebookId,
}: PricebookItemsProviderProps): React.JSX.Element {
  const {
    error: pricebookError,
    loading,
    pricebookItems,
    refreshPricebookItems,
  } = usePricebookItems(initialPricebookId);
  const [items, setItems] = React.useState<PricebookItem[]>([]);
  const { token, user } = useUserContext();

  React.useEffect(() => {
    setItems(pricebookItems);
  }, [pricebookItems]);

  const updateItemsStatus = React.useCallback(
    async (targetPricebookId: number, active: boolean) => {
      try {
        // Optimistic update
        setItems((prevItems) =>
          prevItems.map((item) => (item.pricebookID === targetPricebookId ? { ...item, isActive: active } : item))
        );

        await updatePricebookItemsStatus(
          {
            pricebookID: targetPricebookId.toString(),
            companyID: user?.companyID?.toString() || '',
            isActive: active,
          },
          token
        );

        refreshPricebookItems();
        toast.success(`Items ${active ? 'activated' : 'deactivated'} successfully`);
      } catch (err) {
        toast.error('Failed to update items status');
        refreshPricebookItems();
      }
    },
    [refreshPricebookItems, token, user?.companyID]
  );

  const moveItems = React.useCallback(
    async (selectedItemIds: number[], targetPricebookId: number) => {
      try {
        await movePricebookItems(
          {
            itemIds: selectedItemIds,
            targetPricebookId: targetPricebookId.toString(),
            companyID: user?.companyID?.toString() || '',
          },
          token
        );

        refreshPricebookItems();
        toast.success('Items moved successfully');
      } catch (err) {
        toast.error('Failed to move items');
        refreshPricebookItems();
      }
    },
    [refreshPricebookItems, token, user?.companyID]
  );

  const copyItem = React.useCallback(
    async (itemId: number): Promise<PricebookItem> => {
      try {
        if (!user?.companyID || !user?.userID) {
          throw new Error('Missing required user data');
        }

        const newItem = await copyPricebookItem(
          {
            itemId,
            companyID: user.companyID.toString(),
            userId: user.userID,
          },
          token
        );

        logger.debug('New item created:', newItem);
        refreshPricebookItems();
        return newItem;
      } catch (err) {
        logger.error('Failed to copy item:', err);
        throw err;
      }
    },
    [refreshPricebookItems, token, user?.companyID, user?.userID]
  );

  const contextValue = React.useMemo(
    () => ({
      copyItem,
      error: pricebookError,
      loading,
      moveItems,
      pricebookItems: items,
      refreshPricebookItems,
      updateItemsStatus,
    }),
    [copyItem, pricebookError, loading, moveItems, items, refreshPricebookItems, updateItemsStatus]
  );

  return (
    <PricebookItemsContext.Provider value={contextValue}>
      {loading ? <Loading /> : children}
    </PricebookItemsContext.Provider>
  );
}

export function usePricebookItemsContext(): PricebookItemContextValue {
  const context = React.useContext(PricebookItemsContext);
  if (!context) {
    throw new Error('usePricebookItemsContext must be used within a PricebookItemsProvider');
  }
  return context;
}

export { PricebookItemsContext };
