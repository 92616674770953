// src/reqs/move-pricebook-items.ts
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface MovePricebookItemsParams {
  itemIds: number[];
  targetPricebookId: string;
  companyID: string;
}

export async function movePricebookItems(params: MovePricebookItemsParams, token: string): Promise<void> {
  const headers = getDefaultHeaders(token);
  const searchParams = new URLSearchParams({
    companyID: params.companyID,
    action: 'move',
  }).toString();

  const response = await fetch(`/api/PricebookItem?${searchParams}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      itemIds: params.itemIds,
      targetPricebookId: params.targetPricebookId,
    }),
  });

  if (!response.ok) handleAsyncError(response);
}
