import * as React from 'react';
import { logError } from '@/utils/log-error';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';
import { CompanyProvider } from '@/contexts/company-context';
import { TaxRatesProvider } from '@/contexts/tax-rates-context';
import { ErrorFallback } from '@/components/core/error-fallback';
import { Layout } from '@/components/hub/layout/layout';
import { ProtectedPage } from '@/components/hub/layout/protected-page';
import { SettingsLayout } from '@/components/hub/settings/layout';

export const route: RouteObject = {
  element: (
    <Layout>
      <Outlet />
    </Layout>
  ),
  children: [
    {
      path: 'settings',
      element: (
        <SettingsLayout>
          <Outlet />
        </SettingsLayout>
      ),
      children: [
        {
          path: 'account',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/account');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2]}>
                      <Page />
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'user/:userID',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/user');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2, 3, 4]}>
                      <Page />
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'billing',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/billing');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1]}>
                      <Page />
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'company',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/company');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2]}>
                      <TaxRatesProvider>
                        <Page />
                      </TaxRatesProvider>
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'notifications',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/notifications');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2, 3]}>
                      <Page />
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'security',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/security');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2, 3, 4]}>
                      <Page />
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'team',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/team');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2]}>
                      <Page />
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'integrations',
          lazy: async () => {
            const { Page } = await import('@/pages/integrations/store');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 4]}>
                      <Page />
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'app-settings',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/app-settings');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2]}>
                      <Page />
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'tax-rates',
          lazy: async () => {
            const { Page } = await import('@/pages/settings/tax-rates');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1]}>
                      <CompanyProvider>
                        <TaxRatesProvider>
                          <Page />
                        </TaxRatesProvider>
                      </CompanyProvider>
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
      ],
    },
  ],
};
