import * as React from 'react';
import deleteClientReq from '@/reqs/delete-client';
import getClients from '@/reqs/get-clients';
import getProperties from '@/reqs/get-properties';

import type { Client } from '@/types/client';
import type { Property } from '@/types/property';
import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { toast } from '@/components/core/toaster';

export interface DeleteClientOpts {
  reloadAfterDelete: boolean;
  showToastFeedback: boolean;
  toastSuccessMessage?: string;
  toastFailureMessage?: string;
}

interface ClientsResponse {
  clients: Client[];
  deleteClient: (client: Client, opts: DeleteClientOpts) => Promise<void>;
  deleteClients: (clientList: Client[], opts: DeleteClientOpts) => Promise<void>;
  error: Error | null;
  inactiveClients: Client[];
  loading: boolean;
  refreshClientList: () => void;
}

const defaultDeleteClientOpts = {
  reloadAfterDelete: false,
  showToastFeedback: false,
};

export function useClients(): ClientsResponse {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  const now = new Date().getTime();

  const [clients, setClients] = React.useState<Client[]>([]);
  const [inactiveClients, _setInactiveClients] = React.useState<Client[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [lastLoaded, setLastLoaded] = React.useState<number>(now);

  React.useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async (): Promise<void> => {
      setLoading(true);
      try {
        // Fetch clients and properties in parallel
        const [clientsResponse, propertiesResponse] = await Promise.all([
          getClients({ companyID }, abortController, token),
          getProperties({ companyID, action: 'getProperty' }, abortController, token),
        ]);

        // Create a map of clientID to properties
        const propertiesByClient = propertiesResponse.reduce<Record<number, Property[]>>((acc, property) => {
          const clientID = property.clientID;
          if (!acc[clientID]) {
            acc[clientID] = [];
          }
          acc[clientID].push(property);
          return acc;
        }, {});

        // Merge properties into clients
        const clientsWithProperties = clientsResponse.map((client) => ({
          ...client,
          properties: propertiesByClient[client.clientID] || [],
        }));

        setClients(clientsWithProperties);
      } catch (err) {
        setClients([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [companyID, lastLoaded, token]);

  const refreshClientList = (): void => {
    setLastLoaded(new Date().getTime());
  };

  const deleteClient = async (client: Client, opts: DeleteClientOpts = defaultDeleteClientOpts): Promise<void> => {
    const abortController = new AbortController();
    try {
      setLoading(true);
      setError(null);

      await deleteClientReq(
        {
          clientID: client.clientID.toString(),
          companyID,
        },
        abortController,
        token
      );

      if (opts.reloadAfterDelete) setLastLoaded(new Date().getTime());
      if (opts.showToastFeedback) toast.success(opts.toastSuccessMessage);

      setLoading(false);
    } catch (e) {
      const exception = e as Error;
      if (exception.name !== 'AbortError') {
        logger.error(e);
        if (opts.showToastFeedback) toast.error(opts.toastFailureMessage);
        setError(exception);
        setLoading(false);
      }
    }
  };

  const deleteClients = async (clientList: Client[], opts: DeleteClientOpts): Promise<void> => {
    const abortController = new AbortController();
    try {
      setLoading(true);
      setError(null);

      await Promise.all([
        ...clientList.map((client) =>
          deleteClientReq(
            {
              clientID: client.clientID.toString(),
              companyID,
            },
            abortController,
            token
          )
        ),
      ]);

      if (opts.reloadAfterDelete) setLastLoaded(new Date().getTime());
      if (opts.showToastFeedback) toast.success(opts.toastSuccessMessage);

      setLoading(false);
    } catch (e) {
      const exception = e as Error;
      if (exception.name !== 'AbortError') {
        logger.error(e);
        if (opts.showToastFeedback) toast.error(opts.toastFailureMessage);
        setError(exception);
        setLoading(false);
      }
    }
  };

  return {
    clients,
    deleteClient,
    deleteClients,
    error,
    inactiveClients,
    loading,
    refreshClientList,
  };
}
